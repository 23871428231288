[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #ff0100;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --black-opt3: rgba(0, 0, 0, 0.7);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="mode-light"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #ff0100;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7a52f4;
  --saas-main-color: #4e2c5b;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #f8fafc;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030e;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4a4a4a;
  --saas-table-btn-border1: #bc20fe;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ed3ee;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4bbedc;
  --saas-download-color: #a833cc;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31b6d1;
  --saas-investor-not-read-color: #cfbfff;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #aaa8aa;
  --saas-table-th-bg: #262628;
  --saas-contact-textarea-border-color: #4e4e4e;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #ff0100;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --login-bg1: #4A4C51;
  --login-bg2: #2E1B3F;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
[data-theme="summer-capital-mode"] {
  --main-bg-color: #000;
  --main-color: #D9A527;
  --saas-main-color: #693A0F;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #f8fafc;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030e;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4a4a4a;
  --saas-table-btn-border1: #D9A527;
  --saas-table-btn-bg1: rgba(217, 165, 39, 0.2);
  --saas-table-btn-border2: #EB892E;
  --saas-table-btn-bg2: rgba(235, 137, 46, 0.2);
  --saas-product-label-bg1: #daa729;
  --saas-product-label-bg2: #693a10;
  --saas-investor-label-bg: rgba(217, 165, 39, 0.2);
  --saas-favorite-bg2: #4bbedc;
  --saas-download-color: #FFDD8C;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31b6d1;
  --saas-investor-not-read-color: #FFE7AC;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #aaa8aa;
  --saas-table-th-bg: #262628;
  --saas-contact-textarea-border-color: #4e4e4e;
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #ff0100;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --login-bg1: #6C3500;
  --login-bg2: #201102;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.w02wL_ej {
  display: inline-block;
  text-align: center;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  line-height: 22px;
}
.__4syvc26e {
  max-width: var(--max-width);
  margin: auto;
}
.__4syvc26e .ywufVXrg {
  max-width: var(--max-width) !important;
  display: flex;
  justify-content: space-between;
}
.__4syvc26e .B9hjOdmT {
  height: 250px;
  margin: auto;
  background: var(--module-bg);
  padding: 19px;
}
.__4syvc26e .B9hjOdmT .zbYwzIS7 {
  font-family: var(--CommonMedium);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--white);
  margin-bottom: 16px;
}
.__4syvc26e .B9hjOdmT .CKlb1Xi_ {
  margin-top: 20px;
  font-size: 14px;
  font-family: var(--CommonMedium);
}
.__4syvc26e .B9hjOdmT .CKlb1Xi_ .uiPl6d3m {
  font-size: 22px;
  font-family: var(--CommonBold);
}
.__4syvc26e .B9hjOdmT .CKlb1Xi_ .kocjDc6b {
  height: 32px;
  background: var(--main-bg-color);
  border-radius: 4px;
}
.__4syvc26e .B9hjOdmT .CKlb1Xi_ .l8Bx65ix {
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  font-family: var(--CommonBold);
}
.__4syvc26e .B9hjOdmT .CKlb1Xi_ .ant-input[disabled] {
  padding-right: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  height: 32px;
  line-height: 32px;
  background: var(--main-bg-color);
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-family: var(--CommonMedium);
  font-weight: 400;
  color: var(--white);
  display: inline-block;
}
.__4syvc26e .B9hjOdmT .ud_M8ds9 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.__4syvc26e .B9hjOdmT .v6v8g2hE {
  font-family: var(--CommonRegular);
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: var(--white);
  margin-bottom: 16px;
}
.__4syvc26e .B9hjOdmT .yiKIgHUH {
  display: flex;
  align-items: center;
  padding: 27px 0;
}
.__4syvc26e .B9hjOdmT .yiKIgHUH .KvMa77H8 {
  width: 30%;
  height: 32px;
  margin: 0 35px;
}
.__4syvc26e .B9hjOdmT .yiKIgHUH .hr9AFzOI {
  width: 80px;
}
.__4syvc26e .cOqZj3sy {
  margin: auto;
  background: var(--module-bg);
  padding: 19px;
}
.__4syvc26e .cOqZj3sy .K_Ir1A2Q .v6v8g2hE {
  font-family: var(--CommonRegular);
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: var(--white);
  margin-bottom: 16px;
}
.__4syvc26e .cOqZj3sy .K_Ir1A2Q .VRYQs60y {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  color: var(--main-color);
  cursor: pointer;
}
.__4syvc26e .cOqZj3sy .K_Ir1A2Q .VRYQs60y:hover {
  color: var(--main-text-hover-color);
}
.__4syvc26e .rEg6XOJ3 {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid var(--border-color);
}
.__4syvc26e .AzcQh7Cn {
  width: 100%;
  height: 1px;
  margin: 24px 0;
  border-bottom: 1px solid var(--border-color);
}
.__4syvc26e .pXARGPiq {
  margin: auto;
  background: var(--module-bg);
  padding: 24px;
}
.__4syvc26e .pXARGPiq .v6v8g2hE {
  font-size: 22px;
  color: var(--white);
}
.__4syvc26e .pXARGPiq .__1_oa2pL2 {
  margin-bottom: 24px;
}
.__4syvc26e .pXARGPiq .YDVzRzBa {
  display: flex;
}
.__4syvc26e .pXARGPiq .YDVzRzBa .j5ErW5fg {
  width: 100px;
  margin-right: 100px;
}
.__4syvc26e .pXARGPiq .cvyG8bAC {
  height: 48px;
  background-color: var(--table-heading-bg);
  display: flex;
  align-items: center;
  margin: 24px 0;
}
.__4syvc26e .pXARGPiq .cvyG8bAC .Kkvrk3aC {
  width: 86px;
  margin: 0 105px 0 16px;
}
.__4syvc26e .pXARGPiq .BJZVPUrT {
  display: flex;
}
.__4syvc26e .pXARGPiq .BJZVPUrT .__9pVHAMzb {
  width: 86px;
  margin: 0 105px 0 16px;
}
.__4syvc26e .pXARGPiq .T58Qhu7O {
  margin: 17px 0 9px;
}
.__4syvc26e .hSJ1IUxP {
  margin: auto;
  margin-top: 15px;
  background: var(--module-bg);
  padding: 24px;
}
.__4syvc26e .hSJ1IUxP .v6v8g2hE {
  font-size: 22px;
  color: var(--white);
}
.__4syvc26e .hSJ1IUxP .__16iLDIX1 {
  padding: 24px;
  height: 600px;
  overflow: auto;
  background-color: var(--main-bg-color);
}
.__4syvc26e .gcuQi_7S ._6_Me2uU {
  width: 130px;
  margin-right: 16px;
}
.__4syvc26e .gcuQi_7S .SVc9pSdg {
  width: 166px;
}
.S_hBWlDy {
  background: var(--btn-green-bg);
  cursor: default;
}
.F0_16e2_ {
  background: var(--btn-grey-bg);
  cursor: default;
}
.mlT05uRk {
  padding: 30px 20px;
  font-family: var(--CommonRegular);
  font-size: 14px;
}
.mlT05uRk .v6v8g2hE {
  font-size: 24px;
  font-family: var(--CommonBold);
  margin: 24px 0;
}
.mlT05uRk .DGGP0PKr {
  font-size: 22px;
  font-family: var(--CommonRegular);
  margin-bottom: 20px;
  text-decoration: underline;
}
.mlT05uRk .ulaJPDEj {
  margin-bottom: 16px;
}
.mlT05uRk .ulaJPDEj header {
  margin-bottom: 14px;
  font-size: 20px;
}
.mlT05uRk .ulaJPDEj p {
  margin-bottom: 14px;
}

